import { FirebaseOptions } from '@firebase/app';

export interface SocialLoginConfig {
  enabled: boolean;
  clientId: string;
  scope?: string;
}

export interface EuroinnovaLoginConfig extends SocialLoginConfig {
  icon: string;
  name: string;
  baseUrl: string;
}

/**
 * Interface that represents an environment variable
 */
export interface CustomerConfInterface {
  customerTranslations: {
    es: { [key: string]: string };
    en: { [key: string]: string };
    it: { [key: string]: string };
  };
  logoPath: string;
  loginLogoPath?: string;
  themeName: string;
  firebaseConfig: FirebaseOptions;
  canEnterHub: boolean;
  socialLoginConfig: {
    euroinnova: EuroinnovaLoginConfig;
  };
  faviconConfig: {
    faviconIco: string;
    faviconSvg: string;
    appleTouchIcon: string;
    manifest: string;
  };
  gtmId?: string;
}

export const inesaludConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue ' +
        'competenze e tieniti aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'inesalud',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://auth.inesalud.com',
      clientId: 'inesalud-prod',
      enabled: true,
      icon: 'assets/icons/social-login/inesalud.svg',
      name: 'Inesalud'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/inesalud/favicon.ico',
    faviconSvg: 'assets/favicon/inesalud/favicon.svg',
    appleTouchIcon: 'assets/favicon/inesalud/apple-touch-icon.png',
    manifest: 'assets/favicon/inesalud/manifest.webmanifest'
  }
};

export const inesemConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Dirígete hacia el éxito profesional con la formación para líderes que te ofrecemos en nuestra app. ' +
        'Nuestros cursos y másteres impulsarán tu carrera en el sector business para escalar hasta ' +
        'los puestos más codiciados. ',
      homeHashtag: 'Leaders driving change'
    },
    en: {
      homeHashtagText:
        'Move towards career success with the leadership training we offer in our app. ' +
        "Our courses and master's degrees will boost your career in the business sector to climb to the most coveted " +
        'positions.',
      homeHashtag: 'Leaders driving change'
    },
    it: {
      homeHashtagText:
        'Dirigiti al successo professionale con la formazione per leader che ti offriamo sulla nostra app. ' +
        'I nostri corsi e master daranno slancio alla tua carriera nel settore aziendale permettendoti di ' +
        'raggiungere le posizioni più ambite. ',
      homeHashtag: 'Leaders driving change'
    }
  },
  logoPath: 'assets/inesem/logo.png',
  themeName: 'inesem',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://auth.inesem.es',
      clientId: 'inesem-prod',
      enabled: true,
      icon: 'assets/icons/social-login/inesem.svg',
      name: 'Inesem'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/inesem/favicon.ico',
    faviconSvg: 'assets/favicon/inesem/favicon.svg',
    appleTouchIcon: 'assets/favicon/inesem/apple-touch-icon.png',
    manifest: 'assets/favicon/inesem/manifest.webmanifest'
  }
};

export const educaConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue competenze e' +
        'tieniti aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'educa',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const edusportConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue competenze e tieniti ' +
        'aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'edusport',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const enaldeConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue competenze e tieniti ' +
        'aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'enalde',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const esibeConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue ' +
        'competenze e tieniti aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'esibe',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const ineafConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue ' +
        'competenze e tieniti aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'ineaf',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const innovaConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue ' +
        'competenze e tieniti aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'innova',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const rededucaConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Descubre en la App nuestros cursos de diferentes especialidades de la salud. Especialízate y ' +
        'mantente al día en los avances científicos, tecnológicos y normativos. ',
      homeHashtag: 'Suma conocimiento para avanzar en salud'
    },
    en: {
      homeHashtagText:
        'Discover our courses in different health specialties in the App. Specialize and stay up to date on' +
        ' scientific, technological and regulatory advances.',
      homeHashtag: 'Add knowledge to advance in health'
    },
    it: {
      homeHashtagText:
        'Scopri sulla nostra App i corsi delle varie specialità sanitarie. Approfondisci le tue ' +
        'competenze e tieniti aggiornato sui più recenti sviluppi scientifici, tecnologici e normativi. ',
      homeHashtag: 'Amplia le tue conoscenze per progredire nel settore della salute.'
    }
  },
  logoPath: 'assets/inesalud/logo.png',
  loginLogoPath: 'assets/inesalud/login-logo.png',
  themeName: 'rededuca',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://dev-auth.euroinnova.edu.es',
      clientId: 'appeurodev',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};

export const educaopenConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Domina la programación, la ciberseguridad, el desarrollo web y un sinfín de habilidades más ' +
        'con nuestra app de cursos. ¡Abre la puerta a tu futuro digital!',
      homeHashtag: 'Acceso directo al conocimiento digital'
    },
    en: {
      homeHashtagText:
        'Master programming, cybersecurity, web development and a host of other skills with our course ' +
        'app - open the door to your digital future!',
      homeHashtag: 'Direct access to digital knowledge'
    },
    it: {
      homeHashtagText:
        'Diventa un esperto di programmazione, sicurezza informatica, sviluppo web e molte altre competenze ' +
        'con la nostra app. Apri la porta al tuo futuro digitale!',
      homeHashtag: 'Accesso diretto al conoscimento digitale'
    }
  },
  logoPath: 'assets/educaopen/logo.png',
  themeName: 'educaopen',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://auth.educaopen.com',
      clientId: 'educaopen-prod',
      enabled: true,
      icon: 'assets/icons/social-login/educaopen.svg',
      name: 'EducaOpen'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/educaopen/favicon.ico',
    faviconSvg: 'assets/favicon/educaopen/favicon.svg',
    appleTouchIcon: 'assets/favicon/educaopen/apple-touch-icon.png',
    manifest: 'assets/favicon/educaopen/manifest.webmanifest'
  },
  gtmId: 'GTM-WT6S2DK7'
};

export const euroinnovaConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Alcanza tus objetivos profesionales. Descubre en la App nuestros cursos de las diferentes ' +
        'especialidades de nuestras facultades y academies.',
      homeHashtag: '#QuienQuierasSer'
    },
    en: {
      homeHashtagText:
        'Achieve your professional goals. Discover in the App our courses of the different specialities ' +
        'of our faculties and academies.',
      homeHashtag: '#BeWhoYouWantToBe'
    },
    it: {
      homeHashtagText:
        "Raggiungi i tuoi obiettivi professionali. Scopri nell'App i nostri corsi delle diverse " +
        'specializzazioni delle nostre facoltà e accademie.',
      homeHashtag: '#ChiunqueTuVogliaEssere'
    }
  },
  logoPath: 'assets/euroinnova/logo.png',
  themeName: 'euroinnova',
  firebaseConfig: {
    apiKey: 'AIzaSyBb02k82beQncH516zCHCVnbznnZD70cdc',
    authDomain: 'euroinnova-app.firebaseapp.com',
    projectId: 'euroinnova-app',
    storageBucket: 'euroinnova-app.appspot.com',
    messagingSenderId: '880538480812',
    appId: '1:880538480812:ios:18ca7aed392da20066ef09'
  },
  canEnterHub: false,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://auth.euroinnova.edu.es',
      clientId: 'appeuroprod',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'Euroinnova'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/euroinnova/favicon.ico',
    faviconSvg: 'assets/favicon/euroinnova/favicon.svg',
    appleTouchIcon: 'assets/favicon/euroinnova/apple-touch-icon.png',
    manifest: 'assets/favicon/euroinnova/manifest.webmanifest'
  }
};

export const universityConf: CustomerConfInterface = {
  customerTranslations: {
    es: {
      homeHashtagText:
        'Alcanza tus objetivos profesionales. Descubre en la App nuestros cursos de las diferentes ' +
        'especialidades de nuestras facultades y academies.',
      homeHashtag: '#QuienQuierasSer'
    },
    en: {
      homeHashtagText:
        'Achieve your professional goals. Discover in the App our courses of the different specialities ' +
        'of our faculties and academies.',
      homeHashtag: '#BeWhoYouWantToBe'
    },
    it: {
      homeHashtagText:
        "Raggiungi i tuoi obiettivi professionali. Scopri nell'App i nostri corsi delle diverse " +
        'specializzazioni delle nostre facoltà e accademie.',
      homeHashtag: '#ChiunqueTuVogliaEssere'
    }
  },
  logoPath: 'assets/university/logo.png',
  themeName: 'university',
  firebaseConfig: {
    apiKey: 'AIzaSyDu9Z6YGr3XV2XSN1Cgn8aAdmLKyR_i5p8',
    authDomain: 'university-analytics.firebaseapp.com',
    projectId: 'university-analytics',
    storageBucket: 'university-analytics.appspot.com',
    messagingSenderId: '547526408926',
    appId: '1:547526408926:web:89cdf479875845fe3ba04f',
    measurementId: 'G-C05BNN237J'
  },
  canEnterHub: true,
  socialLoginConfig: {
    euroinnova: {
      baseUrl: 'https://auth.educa.university/',
      clientId: 'educa.university-prod',
      enabled: true,
      icon: 'assets/icons/social-login/euroinnova.svg',
      name: 'University'
    }
  },
  faviconConfig: {
    faviconIco: 'assets/favicon/favicon.ico',
    faviconSvg: 'assets/favicon/favicon.svg',
    appleTouchIcon: 'assets/favicon/apple-touch-icon.png',
    manifest: 'assets/favicon/manifest.webmanifest'
  }
};
